 /* Styles the h3 */
 .mc__title {
    text-align: center;
    font-size: 2rem;
}
  
/* Positions the form in the middle of the modal and ensures the content doesn't jump as status messages get added/removed */
.mc__form-container {
  width: 100%;
  margin: 0 auto;
  transition: height .2s ease;
}

/* Positions Text and form fields nicely in the middle with some spacing. */
.mc__form {
  display: grid;
  grid-template-rows: min-content min-content;
  justify-content: stretch;
  align-items: center;
  transition: height .2s ease;
}

/* Seperates the input fields vertically */
.mc__field-container {
  display: grid;
  grid-row-gap: 30px;
}

.mc__field-container input  {
  width: 100%;
  padding: 1rem;
  display: inline-block;
  border: 1px solid cornflowerblue;
  border-radius: 4px;
  box-sizing: border-box;
}

.mc__field-container input[type="submit"] {
  background-color: cornflowerblue;
  color: white;
  font-weight: bold;
}

.mc__field-container input[type="submit"]:hover {
/* hover properties for button */
background-color: rgb(68, 127, 236);

}


.mc__field-container input {
  background-color: white;
}