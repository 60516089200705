.modal {
  position: fixed;
  left: 0;
  width:100%;
  height: 100%;
  background: rgb(191, 191, 191, 0.8);
  justify-content: center;
  align-items: center;
  display: grid;
  z-index: 99999;
}

.modalBackground {
  width: 100%;
  z-index: -1;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
}

.modalCard {
  background: white;
  height: auto;
}

.modalCard * {
  padding: 0.5rem;
}


.display-none {
  display: none;
}